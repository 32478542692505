import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Talent Network Preview (PQL)",
  "subtitle": "at Contently, 2019",
  "tags": "Design, UX, Front-end",
  "summary": "A limited version of our talent search tool for prospective customers to discover the value of Contently's freelance network.",
  "date": "2019",
  "order": 3,
  "featuredImage": "./talentpql/cover.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "about-the-initiative"
    }}>{`About the initiative`}</h2>
    <p>{`Contently helps brands create accountable content at scale with an end-to-end content marketing platform. We provided tools to manage workflow across teams, own their editorial calendars, get insights on publications, etc. We connected brands to our network with over 140,000+ vetted freelancers backed by a recommendation algorithm that matched our clients with the right collaborators.`}</p>
    <h3 {...{
      "id": "what-was-the-goal"
    }}>{`What was the goal?`}</h3>
    <p>{`We wanted to build a limited preview of our talent recommendation engine to engage prospective customers with the value it provided. We wanted our customers to discover that our vast network could continuously provide them with the right collaborators.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Landing page",
          "title": "Landing page",
          "src": "/static/30098c0b4b2a3582af5ba8b61a8cb5c1/1a152/main1.png",
          "srcSet": ["/static/30098c0b4b2a3582af5ba8b61a8cb5c1/a3397/main1.png 400w", "/static/30098c0b4b2a3582af5ba8b61a8cb5c1/a331c/main1.png 800w", "/static/30098c0b4b2a3582af5ba8b61a8cb5c1/1a152/main1.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "why-did-we-need-to-build-this"
    }}>{`Why did we need to build this?`}</h3>
    <p>{`A product qualified lead (PQL) is a customer lead based on value correlated from using a product through a “try it before you buy it” model. In comparison, a traditional marketing qualified lead (MQL) is based on assumed intent gathered from arbitrary metrics like white paper downloads, page visits and simple questionnaires. It is costly to qualify and gain a new prospect when it’s hard to gauge who our lead is and what they are doing with the data.`}</p>
    <p>{`By offering a first-hand experience, we gain meaningful usage and behavioral insights from user journeys and can help our sales team filter out who qualifies. We remove unnecessary friction from the traditional funnel and can also learn what prevents users from becoming successful with our product.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Talent Network Preview",
          "title": "Talent Network Preview",
          "src": "/static/46b1aa4a9582192c60d79f53bab7d743/1a152/main2.png",
          "srcSet": ["/static/46b1aa4a9582192c60d79f53bab7d743/a3397/main2.png 400w", "/static/46b1aa4a9582192c60d79f53bab7d743/a331c/main2.png 800w", "/static/46b1aa4a9582192c60d79f53bab7d743/1a152/main2.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "team"
    }}>{`Team`}</h3>
    <ul>
      <li parentName="ul">{`Product & UX Design (me)`}</li>
      <li parentName="ul">{`4 Engineers (including me)`}</li>
      <li parentName="ul">{`Product Manager`}</li>
    </ul>
    <h2 {...{
      "id": "measuring-success"
    }}>{`Measuring success`}</h2>
    <h5 {...{
      "id": "quality"
    }}>{`Quality `}</h5>
    <p><strong parentName="p">{`A lead who becomes a real customer.`}</strong>{` We wanted to discover usage behavior patterns that correlated to conversions.`}</p>
    <ul>
      <li parentName="ul">{`What topics did they search?`}</li>
      <li parentName="ul">{`What talents were they looking for?`}</li>
      <li parentName="ul">{`How many times did they search?`}</li>
    </ul>
    <h5 {...{
      "id": "quantity"
    }}>{`Quantity `}</h5>
    <p><strong parentName="p">{`The number of leads who sign up`}</strong>{` is a metric that helps us understand interest, but driving traffic to our preview does not indicate if the lead is qualified.`}</p>
    <h2 {...{
      "id": "user-journey"
    }}>{`User journey`}</h2>
    <p>{`In our initiative kickoff, we discussed what it would take to build a minimum viable product (MVP) to test the idea. It was a simple journey where after 3 searches, our customers would be offer an option to request a full demo. The catch was that it would only return a message saying we’ll get back to you.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "20.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "User journey - revised flow",
          "title": "User journey - revised flow",
          "src": "/static/6bec7b0f42f1f01de4271080749e9f49/16546/user_journey_initial.png",
          "srcSet": ["/static/6bec7b0f42f1f01de4271080749e9f49/a3397/user_journey_initial.png 400w", "/static/6bec7b0f42f1f01de4271080749e9f49/16546/user_journey_initial.png 720w"],
          "sizes": "(max-width: 720px) 100vw, 720px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "revised-journey"
    }}>{`Revised journey`}</h3>
    <p>{`The original flow was too curt and not a friendly user experience. It also wouldn't allow us to gather much behavioral data, so I suggested that we incentivize our customers to tell us more about themselves in return for a few extra searches. They would get to discover more value in our product and we would get data to better quality them.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.75000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "User journey - revised flow",
          "title": "User journey - revised flow",
          "src": "/static/a0539afb181f08ebf632f59c9c9be033/16546/user_journey_revised.png",
          "srcSet": ["/static/a0539afb181f08ebf632f59c9c9be033/a3397/user_journey_revised.png 400w", "/static/a0539afb181f08ebf632f59c9c9be033/16546/user_journey_revised.png 720w"],
          "sizes": "(max-width: 720px) 100vw, 720px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "design-process"
    }}>{`Design process`}</h2>
    <p>{`The internet is a collection of glorified forms, especially in B2B. I used lead generation forms as the basis for my research in developing the initial designs. I noted the good takeaways, less desirable features that we might want to avoid, and any uniqueness.`}</p>
    <h5 {...{
      "id": "basic-requirements"
    }}>{`Basic requirements`}</h5>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Data collection`}</strong>{` - Name, email, company, job title and a general sense of what our lead is looking for.`}</li>
      <li parentName="ul"><strong parentName="li">{`Responsive design`}</strong>{` - Our marketing team informed us that most customers would come from an email. Our full-featured product was also accessible on all devices, so we didn't want to show a subpar product.`}</li>
      <li parentName="ul"><strong parentName="li">{`Real results`}</strong>{` - No one wants to commit to buying 3 scoops of ice cream if there’s a chance that it’ll taste bad. We wanted to offer the full experience in a limited scope.`}</li>
    </ul>
    <h3 {...{
      "id": "landing-page-initial-designs"
    }}>{`Landing page initial designs`}</h3>
    <p>{`With limited time and resources, I took a collaborative approach and merged a few processes as an experimentation. Since this project resembled was for marketing, I opted to skip the low fidelity mocks. I wanted to provide a more realistic representation of elements that would allow our engineers to build in parallel.`}</p>
    <h4 {...{
      "id": "short-and-simple"
    }}>{`Short and simple`}</h4>
    <p>{`Many of the forms I researched required numerous clicking before they provided me any value. Some had a step indicator while others tried to hid the sign of a long questionnaire. I wanted ours to be more straightforward.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Exploring ways to ask our user for information",
          "title": "Exploring ways to ask our user for information",
          "src": "/static/b7bac4986cfe1670253a9ca5fca1ca96/843f9/design1.png",
          "srcSet": ["/static/b7bac4986cfe1670253a9ca5fca1ca96/a3397/design1.png 400w", "/static/b7bac4986cfe1670253a9ca5fca1ca96/a331c/design1.png 800w", "/static/b7bac4986cfe1670253a9ca5fca1ca96/843f9/design1.png 1440w"],
          "sizes": "(max-width: 1440px) 100vw, 1440px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h4 {...{
      "id": "adding-personality"
    }}>{`Adding personality`}</h4>
    <p>{`I wanted our landing page to feel inviting because our product resolves around making connections in the real world. The idea behind a PQL is to move away from tradition, so I wanted to do the same by shifting away from the look and feel of a white paper.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Exploring background designs",
          "title": "Exploring background designs",
          "src": "/static/06cab4d57165b639d6e4728bbe067ed0/1a152/design2.png",
          "srcSet": ["/static/06cab4d57165b639d6e4728bbe067ed0/a3397/design2.png 400w", "/static/06cab4d57165b639d6e4728bbe067ed0/a331c/design2.png 800w", "/static/06cab4d57165b639d6e4728bbe067ed0/1a152/design2.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "results-page-initial-designs"
    }}>{`Results page initial designs`}</h3>
    <p>{`Typically, search results are displayed in a list but that isn’t a great way to highlight people nor would it make use of the real estate on the page.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Exploring ways to showcase people",
          "title": "Exploring ways to showcase people",
          "src": "/static/750a6e5025f11aed8133a7a36011e0eb/843f9/design3.png",
          "srcSet": ["/static/750a6e5025f11aed8133a7a36011e0eb/a3397/design3.png 400w", "/static/750a6e5025f11aed8133a7a36011e0eb/a331c/design3.png 800w", "/static/750a6e5025f11aed8133a7a36011e0eb/843f9/design3.png 1440w"],
          "sizes": "(max-width: 1440px) 100vw, 1440px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`My Initial ideas came with unanswered criteria, so I made a few assumptions on the design before using my team to conduct user research.`}</p>
    <ul>
      <li parentName="ul">{`How many freelancers did we want to display to start?`}</li>
      <li parentName="ul">{`Did our landing page design have to match?`}</li>
      <li parentName="ul">{`How did we want to search?`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Initial results page ideas",
          "title": "Initial results page ideas",
          "src": "/static/bfb5df9f4a72ac8ccf9ad51954ddf21e/1a152/design4.png",
          "srcSet": ["/static/bfb5df9f4a72ac8ccf9ad51954ddf21e/a3397/design4.png 400w", "/static/bfb5df9f4a72ac8ccf9ad51954ddf21e/a331c/design4.png 800w", "/static/bfb5df9f4a72ac8ccf9ad51954ddf21e/1a152/design4.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "research-and-iterations"
    }}>{`Research and Iterations`}</h2>
    <p>{`Due to time constraints, I asked each team member to take on the role a prospective customer and held interviews to evaluate the design assumption I had crafted.`}</p>
    <h4 {...{
      "id": "sample-interview-questions"
    }}>{`Sample interview questions`}</h4>
    <ul>
      <li parentName="ul">{`Was my user able to complete their task?`}</li>
      <li parentName="ul">{`Was the design compelling enough to sign up? First impressions?`}</li>
      <li parentName="ul">{`What did you like? What didn’t you like?`}</li>
      <li parentName="ul">{`What change would have sold you (on a particular design)?`}</li>
      <li parentName="ul">{`How did you feel about the flow between the screens?`}</li>
      <li parentName="ul">{`Did the preview bring value?`}</li>
    </ul>
    <h3 {...{
      "id": "interview-takeaways"
    }}>{`Interview takeaways`}</h3>
    <p>{`I held a meeting where we discussed the designs and any technical constraints that would prevent us from moving forward. I made sure any unanswered criteria was also touched upon.`}</p>
    <h5 {...{
      "id": "landing-page"
    }}>{`Landing page`}</h5>
    <ul>
      <li parentName="ul">{`Everyone liked the big icon selection because it made the choice easy to digest. There were concerns on how limited our choices might affect our customer’s desire to try our product but to keep our scope small, we kept it at 4.`}</li>
      <li parentName="ul">{`There were mixed opinions on splitting the form into multiple steps. We agreed that it would be better as a potential future enhancement.`}</li>
    </ul>
    <h5 {...{
      "id": "results-page"
    }}>{`Results page`}</h5>
    <ul>
      <li parentName="ul">{`Everyone preferred the card display.`}</li>
      <li parentName="ul">{`Blurred cards were the top choice to convey that we had more freelancers in our network.`}</li>
      <li parentName="ul">{`We wanted filters to not resemble a search engine.`}</li>
    </ul>
    <h5 {...{
      "id": "overall-feelings"
    }}>{`Overall feelings`}</h5>
    <ul>
      <li parentName="ul">{`We agreed that the two pages did not have to match in design as they posed different purposes.`}</li>
      <li parentName="ul">{`Most agreed that displaying brands helped to gain trust and that they'd like to see it on both pages.`}</li>
    </ul>
    <h3 {...{
      "id": "expanding-on-card-ideas"
    }}>{`Expanding on card ideas`}</h3>
    <p>{`I put together ideas exploring more showcase style but ultimately felt that simple cards were the best representation of our initiative.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "More card ideas",
          "title": "More card ideas",
          "src": "/static/7dafc651b60c87a2598c5a4b1d5690ae/1a152/design5.png",
          "srcSet": ["/static/7dafc651b60c87a2598c5a4b1d5690ae/a3397/design5.png 400w", "/static/7dafc651b60c87a2598c5a4b1d5690ae/a331c/design5.png 800w", "/static/7dafc651b60c87a2598c5a4b1d5690ae/1a152/design5.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "filtering-and-searches"
    }}>{`Filtering and searches`}</h3>
    <p>{`There were engineering concerns with the possibility of returning zero results which could mislead our customer to think our network didn’t have a match to offer. Since we didn't have data to determine if it posed as a real problem, we documented the zero pairings for a future revisit.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "850px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "29.500000000000004%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Filtering and searching",
          "title": "Filtering and searching",
          "src": "/static/88df0c006553a851a8d680c3782de27f/7fd2c/design6.png",
          "srcSet": ["/static/88df0c006553a851a8d680c3782de27f/a3397/design6.png 400w", "/static/88df0c006553a851a8d680c3782de27f/a331c/design6.png 800w", "/static/88df0c006553a851a8d680c3782de27f/7fd2c/design6.png 850w"],
          "sizes": "(max-width: 850px) 100vw, 850px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "high-fidelity-mocks"
    }}>{`High fidelity mocks`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Landing page mock",
          "title": "Landing page mock",
          "src": "/static/7640dae945a609a6eec1eeefadf6d891/1a152/final1.png",
          "srcSet": ["/static/7640dae945a609a6eec1eeefadf6d891/a3397/final1.png 400w", "/static/7640dae945a609a6eec1eeefadf6d891/a331c/final1.png 800w", "/static/7640dae945a609a6eec1eeefadf6d891/1a152/final1.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Results page mock",
          "title": "Results page mock",
          "src": "/static/e0f6e02c6a540dd04d48af723c4c4cd0/1a152/final2.png",
          "srcSet": ["/static/e0f6e02c6a540dd04d48af723c4c4cd0/a3397/final2.png 400w", "/static/e0f6e02c6a540dd04d48af723c4c4cd0/a331c/final2.png 800w", "/static/e0f6e02c6a540dd04d48af723c4c4cd0/1a152/final2.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Results modal mock",
          "title": "Results modal mock",
          "src": "/static/b5479515c029aa6bac8b277b9be865f9/1a152/final3.png",
          "srcSet": ["/static/b5479515c029aa6bac8b277b9be865f9/a3397/final3.png 400w", "/static/b5479515c029aa6bac8b277b9be865f9/a331c/final3.png 800w", "/static/b5479515c029aa6bac8b277b9be865f9/1a152/final3.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "engineering-challenges"
    }}>{`Engineering challenges`}</h2>
    <p>{`We stashed ideas that ranked high in engineering effort and produced a low return in value. We wanted further data to inform any assumptions we had so our primary focus was to fill any missing interaction gaps.`}</p>
    <ul>
      <li parentName="ul">{`What happens when a customer leaves the page but comes back?`}</li>
      <li parentName="ul">{`Can our customer share their results with their colleagues?`}</li>
      <li parentName="ul">{`What if the same user registers more than once?`}</li>
    </ul>
    <h2 {...{
      "id": "launch-screenshots"
    }}>{`Launch screenshots`}</h2>
    <h3 {...{
      "id": "landing-page-1"
    }}>{`Landing page`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Landing page",
          "title": "Landing page",
          "src": "/static/ccc6c6e7b882f3c93f1d5cf43f6caf05/1a152/live1.png",
          "srcSet": ["/static/ccc6c6e7b882f3c93f1d5cf43f6caf05/a3397/live1.png 400w", "/static/ccc6c6e7b882f3c93f1d5cf43f6caf05/a331c/live1.png 800w", "/static/ccc6c6e7b882f3c93f1d5cf43f6caf05/1a152/live1.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Landing page, responsive",
          "title": "Landing page, responsive",
          "src": "/static/1992f6b1b99c8a45efc531961908972f/1a152/live_responsive1.png",
          "srcSet": ["/static/1992f6b1b99c8a45efc531961908972f/a3397/live_responsive1.png 400w", "/static/1992f6b1b99c8a45efc531961908972f/a331c/live_responsive1.png 800w", "/static/1992f6b1b99c8a45efc531961908972f/1a152/live_responsive1.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "results-page-1"
    }}>{`Results page`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Results page",
          "title": "Results page",
          "src": "/static/8b2fb47709eb8fca984b061326be5a04/1a152/live2.png",
          "srcSet": ["/static/8b2fb47709eb8fca984b061326be5a04/a3397/live2.png 400w", "/static/8b2fb47709eb8fca984b061326be5a04/a331c/live2.png 800w", "/static/8b2fb47709eb8fca984b061326be5a04/1a152/live2.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Results page, responsive",
          "title": "Results page, responsive",
          "src": "/static/5c6c1c062f7a5284acad283f9504e35c/1a152/live_responsive2.png",
          "srcSet": ["/static/5c6c1c062f7a5284acad283f9504e35c/a3397/live_responsive2.png 400w", "/static/5c6c1c062f7a5284acad283f9504e35c/a331c/live_responsive2.png 800w", "/static/5c6c1c062f7a5284acad283f9504e35c/1a152/live_responsive2.png 1600w", "/static/5c6c1c062f7a5284acad283f9504e35c/7f8a1/live_responsive2.png 1700w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "results-modal"
    }}>{`Results modal`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Landing page",
          "title": "Landing page",
          "src": "/static/5c9bc84fc0875c46a46544cf73ea7019/1a152/live3.png",
          "srcSet": ["/static/5c9bc84fc0875c46a46544cf73ea7019/a3397/live3.png 400w", "/static/5c9bc84fc0875c46a46544cf73ea7019/a331c/live3.png 800w", "/static/5c9bc84fc0875c46a46544cf73ea7019/1a152/live3.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      